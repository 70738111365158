import { Box, BoxProps } from '@chakra-ui/layout';

const FullWidthContainer: React.FC<BoxProps> = ({ children, bg, ...rest }) => {
  return (
    <Box width="full" bg={bg} {...rest}>
      {children}
    </Box>
  );
};

export default FullWidthContainer;
